import logger from '@core/logger';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';

import PaymentPagePayModelFactory from '@legacy/application/mobSite/components/paymentPage/PaymentPagePayModelFactory';
import CreditsExchangeModel from '@legacy/application/mobSite/components/credits/models/CreditsExchangeModel';

import {setReady} from '../../common/utils/setPaymentProcessingStatus';
import isCardPaymentType from '../../forms/card/utils/isCardPaymentType';
import parsePayParamsBeforePayment from './parsePayParamsBeforePayment';

/**
 * DISCLAIMER: all methods below used to pay in react app with Backbone models, it's temporary solution
 * This code must be removed after implementation payment method with GraphQl mutation
 */

/**
 * Get pay model form factory and return promise with save model
 * Server answers in resolve/reject
 * @deprecated
 * @param {string} method
 * @param {string} action
 * @param {boolean} withRedirectPayment
 * @param {string} scenario
 * @param {ViaEnum} prevVia
 * @param {string} formName
 * @param {object} params
 * @param {object} altMethodsSettings
 * @returns {Promise<void>}
 */
const backboneModelPay = ({
  method,
  action,
  prevVia,
  scenario,
  params,
  formName,
  withRedirectPayment,
  altMethodsSettings,
}) => {
  return new Promise((resolve) => {
    const payModel =
      action === PAYMENT_ACTIONS.EXCHANGE_CREDIT
        ? CreditsExchangeModel.getInstance({
            packageId: params.stockId,
            feature: params.prevVia,
          })
        : PaymentPagePayModelFactory.getInstance().getModel(
            // Need clear model before next pay try
            isCardPaymentType(method),
            {
              method,
              prevVia,
              via: action,
              formName,
              scenario,
              altMethodsSettings,
            },
            action === PAYMENT_ACTIONS.MICRO_FEATURES,
          );

    payModel.SKIP_RESPONSE_META_ERROR_REDIRECT = true;

    payModel.REACT_3DS_PAYMENT = true;

    if (withRedirectPayment) {
      payModel.REACT_REDIRECT_PAYMENT = true;
    }

    payModel.setScenario(scenario);

    payModel.on('pay:success', (paymentAnswer) => {
      resolve(paymentAnswer);
    });

    payModel.on('pay:fail', (paymentAnswer) => {
      resolve({
        ...paymentAnswer,
        status: false,
      });
    });

    payModel.on('pay:reactRedirectPayment', (paymentAnswer) => {
      resolve({
        ...paymentAnswer,
        /**
         * @see checkRedirectPayment.js
         * @see continueExternalPaymentInAnotherWindow
         */
        isAllowedRedirect: true,
      });
    });

    /**
     * @TODO: remove this and dependent code in src/packages/dating/credits/utils/usePayForCredits.js
     * Use unified method to remember payment paymentData before redirect
     */
    payModel.on('pay:redirect', (paymentAnswer) => {
      resolve({
        ...paymentAnswer,
        redirect: true,
        status: true,
      });
    });

    payModel.on('pay:cancel', () => {
      // Unlock button when aborting external payment
      setReady();
    });

    payModel.on('invalid', (model, error) => {
      logger.sendError('[backboneModelPay] payModel invalid', error);
    });

    payModel.save(parsePayParamsBeforePayment(params));
  });
};

export default backboneModelPay;
