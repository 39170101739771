import getPayMethodWithBackboneModel from '@core/payment/payProcess/utils/getPayMethodWithBackboneModel';
import type {
  PaymentDataRequest,
  PaymentDataResponse,
} from '@core/payment/payProcess/types/paymentFlow';

/**
 * Pay with Backbone model and return pay answer
 * @deprecated
 */
const backbonePay = async (
  paymentData: PaymentDataRequest,
): Promise<PaymentDataResponse> => {
  const pay = getPayMethodWithBackboneModel({
    method: paymentData.method,
    action: paymentData.via,
    prevVia: paymentData.prevVia,
    payFromPopup: paymentData.payFromPopup || false,
    formName: paymentData.formName,
    withRedirectPayment: paymentData.withRedirectPayment,
    altMethodsSettings: paymentData.altMethodsSettings,
    replaceRedirectToPostMessage: paymentData.replaceRedirectToPostMessage,
  });

  return {
    ...(await pay(paymentData)),
  };
};

export default backbonePay;
