import {useState, useEffect} from 'react';

type ModuleResolver<T> = () => Promise<T>;
type UseDeferredComponentsResult<T> = {
  loading: boolean;
  data: T | null;
};

/**
 * This component will lazy load object of components (or functions) which should be split into separate chunk by force
 */
export default function useDeferredComponents<T>(
  moduleResolver: ModuleResolver<T>,
  defaultValue: T | null = null,
): UseDeferredComponentsResult<T> {
  const [components, setComponents] = useState<T | null>(defaultValue);

  useEffect(() => {
    let isMounted = true;
    if (!components) {
      moduleResolver().then((module) => {
        isMounted && setComponents(module);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [moduleResolver, components]);

  return {
    loading: !components,
    data: components,
  };
}
