import Iterator from './Iterator';

var ReverseIterator = function (items) {
    items.reverse();
    this.items = items;
    this.position = 0;
};

ReverseIterator.prototype.__proto__ = Iterator.prototype;


export default ReverseIterator;
