import logger from '@core/logger';

/**
 * @classdesc Proxy class for providing access to functionality.
 * @see SystemNotificationsService, PopupService
 */
class AbstractProxyService<T = object> {
  protected proxy?: T;

  /**
   * @public
   */
  setProxy(proxy: T) {
    this.proxy = proxy;
  }

  /**
   * @abstract
   */
  getName() {
    throw new Error(
      '[AbstractProxyService] You have to set implementation for "getName"',
    );
  }

  /**
   * Returns true if required methods were already set.
   */
  isProxyAvailable = (sendErrorToSentry = true) => {
    const isProxyAvailable = Boolean(this.proxy);

    if (sendErrorToSentry && !isProxyAvailable) {
      // To receive stack trace
      logger.captureException(
        new Error(`[${this.getName()}] No available proxy`),
      );
    }

    return isProxyAvailable;
  };
}

export default AbstractProxyService;
