import type {ForwardedRef, MutableRefObject} from 'react';
import {useRef, useImperativeHandle} from 'react';

/**
 * Creates a forward ref fallback using the provided forwarded ref.
 */
const useForwardedRefFallback = <T>(
  /**
   * The forwarded ref from the parent component.
   */
  forwardedRef?: ForwardedRef<T>,
): MutableRefObject<T | null> => {
  const ref = useRef<T | null>(null);

  // copy link to current of local ref into forwarded ref current
  useImperativeHandle(forwardedRef, () => ref.current);

  return ref;
};

export default useForwardedRefFallback;
