var Iterator = function (items) {
    this.items = items;
    this.position = 0;
};

Iterator.prototype = {
    size: function () {
        return this.items.length;
    },

    hasNext: function () {
        return this.position < this.size() - 1;
    },

    next: function () {
        return this.items[this.position++];
    }
};

export default Iterator;
