import jQuery from 'jquery';

(function($) {
    /**
     * SVG <image> tag haven't 'complete' property, so we save urls of
     * successfully loaded images and then call callback immediately for images with the same url
     * instead of create new Image() and wait for 'load' event.
     * It's useful for example while chat room participants list update to avoid SVG avatars blinking.
     */
    const loadedCache = {};

    /**
     * Calls callback on image's complete property change.
     */
    $.fn.completeChange = function completeChange(callback) {
        this.each(function() {
            // Second solution fits for SVG <image> tag instead of <img>
            const src = this.src || this.href.baseVal;
            const loaded = loadedCache[src] || this.complete;

            if (!loaded) {
                /**
                 * Cant simply subscribe to 'load' or 'error' event due to
                 * browsers inconsistency about SVG specification.
                 * All the problem - that SVG <image> tag haven't any 'load' events
                 * on MacOS Safari. So we need to create new image instance and
                 * subscribe on this load event throwing callback at the end.
                 */
                var img = new Image();
                img.src = src;

                img.addEventListener('load', (function () {
                    loadedCache[src] = true;
                    callback.call(this, true);
                }).bind(this));
                img.addEventListener('error', callback.bind(this, false));
            } else {
                callback.call(this, loaded);
            }
        });
    };
}(jQuery));
