import without from 'lodash/without';
import {forwardRef, useState} from 'react';
import {createPortal} from 'react-dom';

/**
 * Is used to render React components from Backbone views.
 * @deprecated
 */
const Portals = forwardRef((props, ref) => {
  const [portals, setPortals] = useState([]);
  if (!ref.current) {
    /**
     * Is used as `addPortal` function to pass into RegionLayout and Views.
     * @param {HTMLElement} el
     * @param {Object} jsx
     * @return {Function} - function to remove added portal.
     */
    ref.current = (el, jsx) => {
      const portal = createPortal(jsx, el);
      setPortals((prevPortals) => [...prevPortals, portal]);
      return () => {
        setPortals((prevPortals) => without(prevPortals, portal));
      };
    };
  }
  return portals;
});

export default Portals;
