import {useRef} from 'react';

/**
 * React hook for remembering previous value during component lifecycle
 */
const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>();
  const previous = ref.current;
  ref.current = value;
  return previous;
};

export default usePrevious;
