import React, {Component, Fragment} from 'react';
import uniqueId from 'lodash/uniqueId';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

// Legacy. Should be removed and refactored
// eslint-disable-next-line no-restricted-imports
import Region from '@legacy/application/components/application/RegionLayout';

import Portals from './Portals';

/**
 * @deprecated
 */
export default class RenderBackbone extends Component {
  selector = React.createRef();

  portalsRef = React.createRef();

  static propTypes = {
    view: PropTypes.func.isRequired,
    options: PropTypes.objectOf(PropTypes.any),
    /**
     * Sometimes we need to operate with views methods.
     * If value is set - rendered view will be attached to 'window.app'
     */
    registerGlobalName: PropTypes.string,
    className: PropTypes.string,
    onViewUpdate: PropTypes.func,
  };

  static defaultProps = {
    options: {},
    registerGlobalName: '',
  };

  constructor() {
    super();

    if (!window?.$) {
      /**
       * Need to make jQuery available globally for Backbone code.
       * Remove after full transition to React
       */
      window.$ = require('jquery');
    }
  }

  componentDidMount() {
    this.region = new Region(
      this.selector.current,
      this.selector.current.parent,
      this.addPortal,
    );

    this.renderView();
  }

  /**
   * There are 2 cases that must call re-render:
   * 1. Model changed. But model change can be tracked only by it's own ID
   * 2. Any other option was changed except model.
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    const {options} = this.props;
    const {options: prevOptions} = prevProps;

    const modelHaveChanged =
      options.model &&
      prevOptions.model &&
      options.model.cid !== prevOptions.model.cid;

    const optionsHaveChanged = !isEqual(
      omit(options, 'model', 'region'),
      omit(prevOptions, 'model', 'region'),
    );

    if (modelHaveChanged || optionsHaveChanged) {
      this.region.close();
      this.renderView();
    }
  }

  componentWillUnmount() {
    this.region.close();
  }

  addPortal = (...args) => this.portalsRef.current(...args);

  renderView() {
    const view = this.region.render(this.props.view, this.props.options);
    this.props.onViewUpdate && this.props.onViewUpdate(view);
    if (this.props.registerGlobalName) {
      window.app[this.props.registerGlobalName] = view;
    }
  }

  render() {
    return (
      <Fragment>
        <div
          id={uniqueId()}
          ref={this.selector}
          className={this.props.className}
        />
        <Portals ref={this.portalsRef} />
      </Fragment>
    );
  }
}
