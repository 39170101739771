import type {ReactElement} from 'react';

/**
 * Check if node is a React component or plain HTML tag.
 * @example
 *     isReactComponent(<Button />); // true
 *     isReactComponent(<div />); // false
 */
const isReactComponent = (node: ReactElement): boolean =>
  typeof node.type === 'function' || typeof node.type === 'object';

export default isReactComponent;
