import {useCallback, useRef} from 'react';

/**
 * Similar to `useCallback` but doesn't require dependencies.
 * Returning callback is never changed.
 */
const useEventCallback = <Args extends unknown[], R>(
  fn: (...args: Args) => R,
): ((...args: Args) => R) => {
  const ref = useRef(fn);
  ref.current = fn;
  return useCallback((...args: Args): R => ref.current(...args), []);
};

export default useEventCallback;
